import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import jump from 'jump.js';
//.. geht nicht in debug browser ud dev env?
// am ende nicht vergessen AOS.init();

const scroollableFadeUp = document.querySelectorAll('.scroollableFadeUp');

scroollableFadeUp.forEach((each) => {
  each.setAttribute('data-aos', 'fade-up');
  each.setAttribute('data-aos-offset', '80');
  each.setAttribute('data-aos-duration', '1000');
  each.setAttribute('data-aos-anchorPlacement', 'top-bottom');
});

window.addEventListener('load', () => {
  AOS.init();
});

window.addEventListener('resize', () => {
  AOS.refresh();
});
