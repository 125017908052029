import Standard from '../../../js/Libraries/Standard';
import jump from 'jump.js';

export default class Navbar extends Standard {
  constructor() {
    super('.z-nav');
    if (!super.exist()) return;
    this.burger = super.set('.z-burger');
    this.pages = super.set('.z-nav-pages');
    this.navLinks = super.set('.z-nav-page', true);
    this.links = super.set('a', true);
    this.speed = 0.65;

    this.events();
  }

  jump_to(e) {
    e.preventDefault();
    const target = e.target.dataset.link;
    jump('#' + target, {
      duration: (distance) => Math.abs(distance),
    });
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width < 991.98) {
      this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
      this.pages.dummy = '';
      this.burger.classList.toggle('z-toggle');
    }
  }

  events() {
    this.burger.addEventListener('click', () => {
      if (this.pages.dummy) {
        this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
        this.pages.dummy = '';
      } else {
        this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
        this.pages.dummy = 'x';
      }
      this.burger.classList.toggle('z-toggle');
      this.navLinks.forEach((link, index) => {
        var speed_pro = index / 8 + this.speed - 0.35;
        if (link.style.animation) {
          link.style.animation = '';
        } else {
          link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
        }
      });
    });
    this.links.forEach((element) => {
      element.addEventListener('click', (e) => this.jump_to(e));
    });
  }
}
